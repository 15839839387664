
.roadmap-card {
  background-color: #fff;
  border-radius: 1.5rem;
}

.roadmap-card-title {
  margin: 0;
  color: #6A8397;
  margin-bottom: 1rem;
}

.roadmap-steps {
  margin-top: 40px;
}
.roadmap-card .p-steps-title {
  font-size: .7rem;
}

.roadmap-steps .p-steps-item {
  flex: 0 0 calc(100% / 6);
  max-width: calc(100% / 6);
}

.roadmap-steps .p-steps-title {
  text-align: center;
  white-space: break-spaces;
}

.roadmap-steps .p-steps .p-steps-item::before {
  top: 43%;
}

.p-menuitem-link:focus,
.p-menuitem-link:focus-visible {
  outline: 0 !important;
  box-shadow: none !important;
}


.roadmap-card .title-separator {
  margin: 0 auto;
  height: 2px;
  background-color: #F6F7FB;
}

.roadmap-card button {
  background-color: transparent;
  border: 0;
  color: #1479FF;
  font-weight: 600;
  font-size: .9rem;
}

.roadmap-details-description {
  color: #6A8397;
}

.roadmap-details-subtitle {
  color: #6A8397;
  font-weight: 600;
  font-size: 1rem;
}

.roadmap-details .btn-container {
  text-align: right;
}