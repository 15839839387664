.sidebar-shool {
  min-width: 270px;
}

.sidebar-logo img {
  max-width: 100%;
}

.sidebar-logo {
  background: url('https://thumbs.dreamstime.com/b/car-logo-white-background-97122684.jpg') no-repeat center center;
  background-size: cover;
  height: 90px;
  width: 80%;
  text-align: center;
  margin: 0 auto;
}

.sidebar-nav {
 position: sticky;
 left: 0;
 top: 0;
 z-index: 3;
}