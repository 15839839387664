.body-student {
  min-height: 80vh;
  background-color: #F4F8FF;
}

.body-student.container {
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}