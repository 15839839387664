.document-card:first-of-type {
  margin-top: 20px;
}

.document-card.p-card .p-card-title {
  font-size: 1.25rem;
}

.document-card .document-preview {
  width: 140px;
  height: 80px;
}
