.login-container {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-link {
  color: #ffffff;
  background: #555;
  border: 1px solid #555;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
}

.login-container .p-password,
.login-container .p-inputtext {
  width: 100%;
}

.login-container .surface-card {
  max-width: 450px;
}