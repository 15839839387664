header, footer {
  min-height: 10vh;
}

.p-tabview-nav-link:focus {
  box-shadow: none !important;
}

.p-button.p-button-secondary:enabled:focus {
  box-shadow: none !important;
}

.p-dialog-header-icon.p-dialog-header-close.p-link:focus {
  box-shadow: none !important;
}

.p-button.p-button-info:enabled:focus {
  box-shadow: none !important;
}

.document-preview {
  background-color: #fff;
}

.custom-file-upload {
  background-color: #fff;
}