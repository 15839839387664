/* footer {
  width: 100%;
  position:sticky;
  bottom:0;
  height:70px;
  display: flex;
  justify-content: space-between;
} */
a {
  text-decoration: none;
}
ul {
  justify-content: space-around;
}