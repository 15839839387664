.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;

}

.logout-btn:hover{
  cursor: pointer;
}