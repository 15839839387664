.avatar-container img {
  max-width: 100%;
}

.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: initial;
  max-width: 15%;
}

.btn-ghost {
  border: 0;
  background-color: transparent;
  color: #6c8797;
  cursor: pointer;
  font-weight: 400;
}

.edit-form {
  padding: 0;
}

.edit-form .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: .75rem;
}

.edit-form .form-group label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: .5rem;
}

.p-datepicker-inline {
  border: 0 !important;
}

.payment-card.p-card .p-card-body {
  padding: 0 1.25rem;
}

.p-dialog .form-error_list {
  margin-bottom: 0;
}