header {
    width: 100%;
    height: 60px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(210, 171, 171);
    padding: 1rem;
}