.cars_select {
  border-radius: 6px;
  padding: .85rem .75rem;
  border: 1px solid #ccc; 
  outline: 0;
  margin-bottom: 1rem;
}

.cars_select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #c7d2fe;
  border-color: #6366F1;
}