.step-two-overlay {
  position: relative;
}

.step-two-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  z-index: 2;
}
.step-two-overlay .pi-lock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, .25);
  font-size: 6rem;
}

.disabled-form {
  pointer-events: none;
}