.custom-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: .35rem;
  height: 3rem;
}

.custom-file-upload input[type="file"]{
  display: none;
}

.step-two-overlay {
  position: relative;
}

.step-two-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  z-index: 2;
}
.step-two-overlay .pi-lock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, .25);
  font-size: 6rem;
}

.disabled-form {
  pointer-events: none;
}

.document-preview {
  width: 198px;
  height: 111px;
  border: 2px solid #e3e3e3;
  border-radius: .35rem;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

.document-preview img {
  width: 100%;
  border-radius: .35rem;
}

.document-preview img[src=""] {
  display: none;
}

.document-preview i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.25rem;
  color: #e3e3e3;
}
