.student-card {
  min-height: 35vh;
  border-radius: 0 0 1.5rem 1.5rem;
  background-color: #1479FF;
}

.student-card button {
  background-color: transparent;
  border: 0;
  color: #6A8397;
}

.student-card-header {
  background-color: #fff;
  border-radius: 0 0 1.5rem 1.5rem;
  color: #6A8397;
}

.student-card-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.student-card-body {
  color: #fff;
  border-radius: 0 0 1.5rem 1.5rem;
}

.avatar-container {
  border-radius: 50%;
  background-color: #fff;
  height: 150px;
  width: 150px;
  margin: 0 auto;
}

.student-card .avatar-upload-btn {
  top: 75%;
  right: 0%;
  color: #fff;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  text-align: center;
  height: 45px;
  width: 45px;
  z-index: 2;
} 

.student-card .notifications-btn,
.student-card .settings-btn,
.student-card .messages-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}